@use '~@amzn/awsui-design-tokens/polaris.scss' as awsui;
@use './base';

.input-container {
  display: flex;
  flex-wrap: wrap;
  order: 0;
  flex-grow: 10;
  margin-right: 2rem;
}

.input-filter {
  order: 0;
  flex-grow: 6;
  width: auto;
  max-width: 728px;
  margin-right: 1rem;
}

.select-filter {
  max-width: 130px;
  flex-grow: 2;
  width: auto;
}

.engine-filter {
  margin-right: 1rem;
}

.filtering-results {
  margin-left: 1rem;
  line-height: 3rem;
  color: awsui.$color-text-form-default;
}

@media (max-width: 1152px) {
  .input-container {
    margin-right: 0;
  }

  .select-filter {
    max-width: none;
  }

  .input-filter {
    width: 100%;
    max-width: none;
    margin-bottom: 1rem;
    margin-right: 0;
  }
}
